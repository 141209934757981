/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@import '~@angular/material/prebuilt-themes/indigo-pink.css';

/* custom classes */
.mat-drawer-content{
    width: 100% !important;
}

.mat-list-base{
    padding-top: 0px !important;
}

.dashboard-container{
    overflow-y: auto;
}

$background-drawer-contatiner: #F5F6F7;
$background-mat-drawer: #39444D;
$color-topbar-text: #FFF;
$background-top-bar: #2196F3;

.mat-light-blue{
    color: #FFF;
    background: $background-top-bar;
}

.main-container{
    display: flex;
    overflow-y: auto;
    height: 100%;
    align-items: baseline;

    .inside-main-container{
        display: flex;
        flex-direction: column;
        margin: 1%;
        width: 98%;
    }

    table{
        width: 100%;
    }

    mat-form-field{
       width: 100%;
    }

    mat-paginator{
      mat-form-field {
        width: unset;
      }
    }
}

.mat-drawer-inner-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.realtime-container{
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  button{
      font-size: 1em;
  }

  .card-content{
      margin-top: 2%;
      box-shadow: none;

  .mat-card-container{
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      align-content: center;
      font-size: 1.2em;
      flex-wrap: wrap;

      .states{
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          min-width: 15%;
          border: 1px solid #ccc !important;
          padding: 16px;
          border-radius: 16px;
          margin-bottom: 2%;

          font-weight: bold;
          span{
            margin-left: 7px;
            span{
              font-weight:normal;
            };
          }
      }
    }
      mat-label{
          font-size: 1.3em;
          font-weight: bold;
      }

  }
}

.offlineCard{

    mat-card{
        background-color: lightyellow;
        height: 300px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        align-content: center;
        flex-direction: column;
    }

    mat-icon{
      font-size: 2.3rem;
      color: orange;
    }
}

.mat-success{
  color: #2196F3;
} 


.rotate-8-s {
    animation: rotation 8s infinite linear;
  }

    @keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.blink-red-1-s {
  animation: blink 1s infinite linear;
}

@keyframes blink {
  0% {
    color:white;
  }
  50% {
    color:red;
  }
  100% {
    color:white;
  }
}

.mr-10{
  margin-right: 10px !important;
}
